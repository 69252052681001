import * as React from "react";
import { useState } from "react";
import DataCaptureForm from "../landingPageComponents/blocks/dataCaptureForm/dataCaptureForm";
//import DataCaptureForm from "../landingPageComponents/blocks/DataCaptureFormNew";
import ContainerForPages from "../containerForPages";
import {makeStyles} from "@material-ui/core/styles/"

function LoginForm(props) {

  const { handleChange, handleEmailSubmit, stage, email, error, handleCodeSubmit } = props;

  const useStyles = makeStyles((theme) => ({
    formContainer:{
      minHeight: `calc(100vh - 454px)`,
      [theme.breakpoints.up("md")]:{
        paddingTop: "48px",
        minHeight: `calc(100vh - 223px)`
      }
    },
  }))

  const classes = useStyles()

  const dataCaptureInputs = [
    {
      inputFieldLabel: "Email",
      inputFieldPlaceholderText: "Enter your email",
      inputFieldType: "Email",
      requiredField: true,
      errorText: "Email is required",
    }
  ]

  function renderStage(currentStage){
    switch(currentStage){
      case 1:
        return <DataCaptureForm  signUpError={error} handleChangeSignup={handleChange} handleEmailSubmitSignup={handleEmailSubmit} dataCaptureInputs={dataCaptureInputs} isLoginPage={true} linkText={"LOGIN"}/>
      case 2:
        return <DataCaptureForm signUpError={error} email={email} isVerifyPage={true} handleCodeSubmit={handleCodeSubmit} />
      default:
        return null;
    }
  }

  return (
    <ContainerForPages  isCoursePage={undefined} dropdownOpen={undefined} isGatedLanding={undefined}>
      <div className={classes.formContainer}>
        {renderStage(stage)}
      </div>
    </ContainerForPages>
  )
}

export default LoginForm;
