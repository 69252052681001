import React, { useState, useEffect } from "react"
import PasswordlessForm from '../components/auth0/PasswordLessForm';
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { navigate } from "gatsby";
import jwtDecode from "jwt-decode";

import Auth from "../components/auth0/AuthenticatorNoRedirect";
import AuthCheck from "../components/auth0/AuthenticatorWithRoute";

export const query = graphql`
  query SignUpPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ... fragSiteSettings
    }
    post: sanityLandingPage(slug: { current: { eq: "sign-up" } }) {
      ... fragLandingPage
      content {
        ... fragSanityCourseTitle
        ... fragSanityAvailableCourses
        ... fragSanityTabsTextboxBlock
        ... fragSanityDropdownWidget
        ... fragSanitySocialMediaPosts
        ... fragSanityTwitterBlock
        ... fragSanityLandingSearchBlock
        ... fragSanityBestBuysTable
        ... fragSanityRecProviders
        ... fragSanityTipChooser
        ... fragSanityBreadcrumb
        ... fragSanityPopularConversationsBlock
        ... fragSanityBigBlockGroup
        ... fragSanityDataCaptureForm
        ... fragSanityHeroSection
        ... fragSanityHeaderBlock
        ... fragSanityTitleSection
        ... fragSanityLatestArticlesBlock
        ... fragSanityCalcReference
        ... fragSanityFilteredQuestionsBlock
        ... fragSanityAdvisersList
        ... fragSanityFundList
        ... fragSmallSpacerBlock
        ... fragWidgetSelection
        ... fragSanityEmailCaptureForm
      }
    }
    dictionary: allSanityDictionary {
      nodes {
        ... dictionaryFragment
      }
    }
  }
`

const SignUp = (props) => {

  const { data, errors } = props;
  const post = data && data.post;
  
  post.dictionary = data && data.dictionary;

  var auth = null;
  auth = Auth();

  useEffect(() => {

    if (typeof window !== 'undefined') {
      var intervalId = setInterval(window.checkStatus, 5000);
      //Every 5 seconds check for the id token to clean up when someone logins in via button/new tab.
    }

  }, []); 


  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;


  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  if (auth == null) {
    return null;
  } else {
    return (
      <AuthCheck route="/members" auth={auth} login={false} signup={true} directToMembers={true}>
      </AuthCheck>
    )
  }

}

export default SignUp;
