import React, { Component } from 'react';
import auth0 from 'auth0-js';
import LoginForm from "../account/loginFormB2C";
import RegisterForm from "../account/registerFormB2C";
import CompleteProfileForm from "../account/completeProfileFormB2C"

class PasswordlessForm extends Component {
  constructor(props) {
    super(props);

    let qsvalue = null;
    let evalue = "";

    this.state = {
      email: '',
      code: '',
      showCodeForm: false, // Initially hide the code form
      showCompleteProfileForm: false, // Initially hide complete profile form
      isAuthenticated: false, // Initially assume the user is not authenticated
      error: null,
      returnUrl: qsvalue,
    };

    if (typeof window !== 'undefined') {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      qsvalue = params.returnUrl;


      if (!this.props.showCompleteProfileForm) {
       // console.log("setting return url", qsvalue)
        localStorage.setItem('returnUrl', qsvalue);
      }

      if ((typeof params.usr != "undefined") && (params.usr != null)) {

        evalue = atob(params.usr);
        this.state.email = evalue;


      }

    }

    const redirectUri = process.env.AUTH0_REDIRECT_URI + "?returnUrl=" + qsvalue;
    // console.log('redirectUri', redirectUri)
    // console.log('qsvalue, end of redirect', qsvalue)

    // Configure Auth0
    this.auth0 = new auth0.WebAuth({
      domain: process.env.AUTH0_DOMAIN,
      clientID: process.env.AUTH0_CLIENTID,
      responseType: 'token id_token',
      redirectUri: redirectUri,
      scope: 'openid email' // Add any additional scopes you need
    });

    var url = null;

    if (typeof window !== 'undefined') {

      url = new URL(window.location);

      //console.log("url", url.searchParams.get("cd"))
      // console.log('url.searchParams', url.searchParams)
      //console.log('window.location', window?.location)

    }

    const pwStart = () => {
      if (!url.searchParams.get("cd")) {
        this.auth0.passwordlessStart({
          connection: 'email',
          send: 'code',
          email: this.state.email
        }, (error, result) => {

          //console.log(result)
          if (error) {
            this.setState({ error: error.description });
          } else {
            // Show the code form
            this.setState({ showCodeForm: true });


          }
        });
      }


    }

    if ((evalue != null) && (evalue != "")) {


        pwStart();



    }

    // Bind functions
    this.handleChange = this.handleChange.bind(this);
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    this.handleCodeSubmit = this.handleCodeSubmit.bind(this);
  }

  handleChange(value) {
  }

  handleEmailSubmit(value) {
    // event.preventDefault();
    this.setState({ email: value });

    //console.log("handleEmailSubmit")

    var url = null;

    if (typeof window !== 'undefined') {

      url = new URL(window.location);

    }


    this.auth0.passwordlessStart({
      connection: 'email',
      send: 'code',
      email: this.state.email
    }, (error, result) => {
      if (error) {
        this.setState({ error: error.description });
      } else {
        // Show the code form
        this.setState({ showCodeForm: true });

        if ((url != null) && (typeof url != "undefined")) {
          url.searchParams.set('cd', true);
          window.history.pushState(null, '', url.toString());

          //console.log("test url")
        }

      }
    });

  }

  handleCodeSubmit(value) {
    // event.preventDefault();

    // Validate the code and get an access token
    this.auth0.passwordlessLogin({
      connection: 'email',
      email: this.state.email,
      verificationCode: value,
      responseType: 'token id_token',
      scope: 'openid email',
      prompt: 'none',
    }, (error, result) => {
      if (error) {
        this.setState({ error: error.description });
      } else {
        // Set the isAuthenticated state to true
        this.setState({ isAuthenticated: true });

        if (typeof window !== 'undefined') {
          //clear the existing Braze ID
          window.brazeLogInReset();
        }

        const profileVal =
          typeof window !== "undefined" && localStorage.getItem("profile");
          //console.log('profileVal', profileVal)
        if (profileVal === true || profileVal === "True") {
          if ((this.state.returnUrl != "") && (this.state.returnUrl != null) && (this.state.returnUrl != "null") && (typeof this.state.returnUrl != "undefined")) {
            //console.log('here')

            if (typeof window !== 'undefined') {
              //console.log('window.location.href = this.state.returnUrl')
              window.location.href = this.state.returnUrl;
            } else {
              window.location.href = '/members';
            }
          } else {
            window.location.href = '/members';
          }
        } else {
          this.setState({ showCompleteProfileForm: true })
        }

        // Redirect to the account page


      }
    });
  }


  render() {
    if (this.state.isAuthenticated) {
      return <div>You are logged in!</div>;
    } else if (this.props.showCompleteProfileForm) {
      return <CompleteProfileForm directToMembers={this.props.directToMembers} />
    } else if (this.state.showCodeForm || this.props.codeForm) {
      return (
        <>
          {this.props.login ?
            <LoginForm handleCodeSubmit={this.handleCodeSubmit} setEmailState={this.setState} error={this.state.error}
              email={this.state.email} stage={2} handleChange={this.handleChange}
              handleEmailSubmit={this.handleEmailSubmit} />
            :
            <RegisterForm handleCodeSubmit={this.handleCodeSubmit} setEmailState={this.setState}
              error={this.state.error} email={this.state.email} stage={2} handleChange={this.handleChange}
              handleEmailSubmit={this.handleEmailSubmit} />
          }
        </>
        // <form onSubmit={this.handleCodeSubmit}>
        //   <label>
        //     Code:
        //     <input
        //       type="text"
        //       name="code"
        //       value={this.state.code}
        //       onChange={this.handleChange}
        //     />
        //   </label>
        //   <button type="submit">Submit</button>
        //   {this.state.error && <div>{this.state.error}</div>}
        // </form>
      );
    } else if (this.props.login || this.props.signup) {
      return (
        <>
          {this.props.login ? <LoginForm setEmailState={this.setState} stage={1} handleChange={this.handleChange}
            handleEmailSubmit={this.handleEmailSubmit} />
            : <RegisterForm setEmailState={this.setState} stage={1} handleChange={this.handleChange}
              handleEmailSubmit={this.handleEmailSubmit} />}
          {/*<form onSubmit={this.handleEmailSubmit}>*/}

          {/*  <label>*/}
          {/*    Email:*/}
          {/*    <input*/}
          {/*      type="email"*/}
          {/*      name="email"*/}
          {/*      value={this.state.email}*/}
          {/*      onChange={this.handleChange}*/}
          {/*    />*/}
          {/*  </label>*/}
          {/*  <button type="submit">Submit</button>*/}
          {/*  {this.state.error && <div>{this.state.error}</div>}*/}
          {/*</form>*/}
        </>
      );
    } else {
      return <></>
    }
  }
}


export default PasswordlessForm;
