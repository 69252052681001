import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { navigate } from "gatsby";
import Layout from "../../containers/layout";
import PasswordlessForm from './PasswordLessForm';
import Auth from "./AuthenticatorNoRedirect";


const AuthenticatorWithRoute = (props) => {

  const { route, login, signup, codeForm } = props;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Try to get the data from localStorage
    const storedData = localStorage.getItem('id_token');

    if (typeof window !== "undefined" && storedData) {
      const tokenData = jwtDecode(storedData);

      if (tokenData.exp > Date.now() / 1000) {
        // Token found and not expired, user is authenticated
        setData(storedData);
      } else {

        setData(null);
      }

      //setData(storedData);
    }

    setLoading(false);
  }, []);



  if (loading) {
    return null;
  }

  if (data) {
    // Navigate to another page, e.g., /newPage
    navigate(route);

    return null;
  }

  return (
       <Layout>
          <PasswordlessForm codeForm={codeForm} signup={signup} login={login} directToMembers={true} />
       </Layout>
  );

}

export default AuthenticatorWithRoute
