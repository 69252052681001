import * as React from "react";
import { useState, useEffect } from "react";
import DataCaptureForm from "../landingPageComponents/blocks/dataCaptureForm/dataCaptureForm";
//import DataCaptureForm from "../landingPageComponents/blocks/DataCaptureFormNew";
import Container from "../container"
import ContainerForPages from "../containerForPages";
import {makeStyles} from "@material-ui/core/styles/"

function CompleteProfileFormB2C(props) {

  const [stage, setStage] = useState(typeof window !== "undefined" ? localStorage.getItem("profile").toLowerCase() : []);
  const [getProfile, setProfile] = useState(typeof window !== "undefined" ? localStorage.getItem("profile").toLowerCase() : []);

  useEffect(() => {
    //if (!isNaN(getProfile)) {
     
    //  if(parseInt(getProfile) === 0){
    //    setStage(1)
    //  } else {
    //    //setStage(parseInt(getProfile))

    //    setStage(getProfile)
    //  }
    //}
    setStage(getProfile)
    console.log("isNAN", stage)
  },[getProfile])

  const useStyles = makeStyles((theme) => ({
    formContainer: {
      minHeight: `calc(100vh - 454px)`,
      [theme.breakpoints.up("md")]:{
        paddingTop: "0px",
        minHeight: `calc(100vh - 223px)`
      },
    },
  }))

  const classes = useStyles()

  const dataCaptureInputs = [
    {
      inputFieldLabel: "First Name",
      inputFieldPlaceholderText: "Your first name",
      inputFieldType: "First Name",
      requiredField: true,
      errorText: "First name is required",
    },
    {
      inputFieldLabel: "Surname (Optional)",
      inputFieldPlaceholderText: "Your surname",
      inputFieldType: "Last Name",
      requiredField: false,
      errorText: "First name is required",
    },
    {
      inputFieldLabel: "Gender",
      inputFieldPlaceholderText: "Select your gender",
      inputFieldType: "Gender",
      requiredField: true,
      errorText: "Gender is required",
    },
    {
      inputFieldLabel: "Age",
      inputFieldType: "Date of Birth",
      inputFieldPlaceholderText: "Enter your age e.g. 48",
      requiredField: true,
      errorText: "Age is required",
    },
  ]

  function renderStage(currentStage){
    switch(currentStage){
      case "1":
        return <DataCaptureForm linkText={"NEXT 1/2"} isProfileCompletePage={true} dataCaptureInputs={dataCaptureInputs} stage={stage} setStage={setStage}/>
      case "2":
        return <DataCaptureForm linkText={"COMPLETE"} isProfileCompletePage={true} dataCaptureInputs={dataCaptureInputs} stage={stage} setStage={setStage} />
      case "true":
        return <DataCaptureForm linkText={"NEXT 1/2"} isProfileCompletePage={true} dataCaptureInputs={dataCaptureInputs} stage={stage} setStage={setStage} />
      default:
        return null;
    }
  }

  return (
    <ContainerForPages isCoursePage={undefined} dropdownOpen={undefined} isGatedLanding={undefined}>
      <div className={classes.formContainer}>
        {renderStage(stage)}
      </div>
    </ContainerForPages>
  )
}

export default CompleteProfileFormB2C;
